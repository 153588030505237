<template>
  <div class="statementAfter">
    <van-dialog
      v-model="showStatementAfter"
      :show-confirm-button="false"
      get-container="#app"
    >
      <div class="title">结算确认</div>
      <div class="centerbox_dialog">
        <div class="flex orderInfo topCol">
          <div>结算价格</div>
          <div>{{ hjPrice }}</div>
        </div>
        <!--            <div class="flex orderInfo">  <div class="btmCol">补押金（元/克）</div> <div class="btmCol">{{repairPrice}}</div> </div>-->
        <div class="flex orderInfo topCol">
          <van-field
            v-model="weight"
            label="结算克重"
            placeholder="请输入结算克重"
            input-align="right"
            size="24px"
            type="number"
            readonly
          />
        </div>
<!--        <div class="tips">
          注：克重可手动修改
        </div>-->
      </div>
      <div class="flex popbtn">
        <div class="gocer closebtn" @click="close">取消</div>
        <div class="gocer confirmbtn" @click="confirm">确定</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import * as api from "@/utils/api";

export default {
  props: ["showStatementAfter", "hjPrice", "freeWeight", "openId", "postTime", "type"],
  inject: ["reload"],
  data() {
    return {
      weight: ""
    };
  },
  methods: {
    // 取消按钮点击事件
    close() {
      // 通知父组件关闭弹窗
      this.$emit("update:showStatementAfter", false);
    },
    async confirm() {
      if (!this.weight) {
        return this.$toast("请输入结算克重"), !1;
      }
      if (Number(this.weight) > this.freeWeight) {
        return this.$toast("结算克重输入有误"), !1;
      }
      this.$emit("update:showStatementAfter", false);
      if (this.type === "mortgage") {
        await api.StockOrderJieSuan({
          openId: this.openId,
          time: this.postTime,
          weight: this.weight
        });
        this.$router.push({
          name: "submitStockExplain"
        });
      } else if (this.type === "material") {
        await api.MaterialOrderJieSuan({
          openId: this.openId,
          time: this.postTime,
          weight: this.weight
        });
        this.$router.push({
          name: "submitMaterialExplain"
        });
      }
    },
    openDialog() {
      this.$emit("update:showStatementAfter", true);
      this.weight = this.freeWeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.van-dialog {
  width: 90% !important;
  padding: 0 40px 30px;
  .title {
    color: #242424;
  }
  .van-field {
    font-size: 28px;
  }
  .van-cell {
    padding: 10px 0 0 !important;
    line-height: 60px !important;
  }
}

.popbtn {
  margin-top: 25px;
  div {
    margin-top: 5px !important;
    border-top: none !important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }
}
.title {
  font-size: 32px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
//.orderInfo{
//    padding: 4px 0;
//}
.gocer {
  font-size: 32px;
  padding: 14px 0;
  margin-top: 30px;
  color: #fff;
  text-align: center;
}
.centerbox_dialog {
  text-align: left;
  // padding: 0 10px;
  font-size: 28px;
}
.closebtn {
  background-color: #d1d1d1;
}
.confirmbtn {
  background-color: #ff2d2e;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.topCol {
  color: #646566;
  line-height: 60px;
  border-bottom: 1px solid #c1c1c1;
}
.btmCol {
  color: #242424;
  line-height: 60px;
}
.tips {
  margin-top: 14px;
  color: #ff2d2e;
  font-size: 20px;
}
</style>
